import React from "react";
import { styled } from "styled-components";
import { queries } from "../../utils/queries.js";

const SectionContainerWrapper = styled.div`
  padding: 20px;

  @media ${queries.tablet} {
    padding: 30px;
  }

  @media ${queries.laptop} {
    padding: 60px;
  }
`;

const SectionContainer = ({ background, children }) => {
  return (
    <SectionContainerWrapper style={{ backgroundColor: background }}>
      {children}
    </SectionContainerWrapper>
  );
};

export default SectionContainer;
