import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../utils/colors.js";
import { queries } from "../utils/queries.js";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { Heading, Subheading, Paragraph } from "./atoms/Typography.js";
import SectionContainer from "./atoms/SectionContainer.js";
import img from "../images/Dziwnow_8-24_62.jpg";

const AboutUsWrapper = styled.div`
  img {
    width: 100%;
  }

  @media ${queries.laptop} {
    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  padding: 20px;
  margin-top: 20px;
  box-shadow: 3px 3px 4px 2px ${COLORS.black100};

  @media ${queries.laptop} {
    margin-top: 0;
  }
`;

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <SectionContainer>
      <AboutUsWrapper id="about_us">
        <Fade duration={1000} delay={500} triggerOnce>
          <Container>
            <Row>
              <Col xs={12}>
                <TextWrapper>
                  <Heading text={t("itroduction.header")} />
                  <Subheading text={t("itroduction.subheader")} />
                </TextWrapper>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Paragraph
                  justified="true"
                  text={t("itroduction.paragraph1")}
                />
                <Paragraph
                  justified="true"
                  text={t("itroduction.paragraph2")}
                />
                <Paragraph
                  justified="true"
                  fontWeight={600}
                  text={t("itroduction.paragraph3")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <ImageWrapper>
                  <img src={img} alt="building" />
                </ImageWrapper>
              </Col>
            </Row>
          </Container>
        </Fade>
      </AboutUsWrapper>
    </SectionContainer>
  );
};

export default AboutUs;
