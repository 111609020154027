import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { COLORS } from "../utils/colors.js";
import { queries } from "../utils/queries.js";
import { Container, Row } from "react-bootstrap";
import { Span } from "./atoms/Typography.js";

const FooterWrapper = styled.div`
  padding: 20px 50px;
  background-color: ${COLORS.white};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 10px;
  }

  @media ${queries.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const AuthorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a{
        font-size: 9px;
        margin-left: 10px;
    }
`;

const Footer = () => {
  const { t } = useTranslation();
  var date = new Date();
  var dateObject = date.getFullYear();

  return (
    <FooterWrapper>
      <Container>
        <Row>
          <TextWrapper>
            <Span
              text={t("footer.name") + " " + dateObject}
              fontWeight="bold"
            />
            <AuthorWrapper>
              <Span uppercase="true" text={t("footer.realization") + ":"} />
              <a href="https://www.ewanazim.pl" target="_blank" rel="noreferrer">
                <Span uppercase="true" text="Ewa Nazim" />
              </a>
            </AuthorWrapper>
          </TextWrapper>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
