import React from "react";
import { styled } from "styled-components";

const MapWrapper = styled.div`
  width: 100%;

  iframe {
    max-width: 100%;
  }
`;

const Map = () => {
  return (
    <MapWrapper>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2344.135330386556!2d14.739721500000002!3d54.0181219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aa850c4814a329%3A0xde22b4550126cfda!2sSpadochroniarzy%20Polskich%208%2F24%2C%2072-420%20Dziwn%C3%B3w!5e0!3m2!1spl!2spl!4v1685981206277!5m2!1spl!2spl&key=AIzaSyCxzysXOlJROaGFknDsqCNda_LU-IFM0FQ"
        width="600"
        height="450"
        style={{ border: "0" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </MapWrapper>
  );
};

export default Map;
