import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { COLORS } from "../utils/colors.js";
import { queries } from "../utils/queries.js";
import { Container, Row, Col } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";
import { Paragraph, Subheading } from "./atoms/Typography.js";
import check_in from "../images/check_in.jpg";
import minibar from "../images/minibar.jpg";
import room_service from "../images/room_service.jpg";

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    transform: scale(1.2);
    transition: 0.3s;

    &:hover {
      transform: scale(1);
    }
  }
`;
const TileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-transform: lowercase;
  text-align: center;

  p:first-of-type {
    font-family: MarckScript;
  }

  p:last-of-type {
    font-size: 10px;
  }

  @media ${queries.tablet} {
    padding: 25px;
  }
  @media ${queries.laptop} {
    height: 250px;
    padding: 50px;

    h3 {
      flex-grow: 1;
    }
  }
`;

const OptionalInfo = styled.p`

`;


const Facilities = () => {
  const { t } = useTranslation();

  const tiles = [
    {
      main: t("facilities.check_in"),
      second: t("facilities.check_in_summup"),
      img: check_in,
      background: COLORS.primary100,
      delay: 500,
    },
    {
      main: t("facilities.bar"),
      second: t("facilities.bar_summup"),
      img: minibar,
      background: COLORS.primary200,
      delay: 1000,
    },
    {
      main: t("facilities.room_service"),
      second: t("facilities.room_service_summup"),
      img: room_service,
      background: COLORS.primary300,
      delay: 1500,
    },
  ];

  return (
    <Container fluid>
      <Row>
        {tiles.map((tile) => (
          <Col xs={12} lg={4} style={{ padding: 0 }} key={tile.main}>
            <Zoom delay={tile.delay} triggerOnce>
              <TileWrapper style={{ backgroundColor: tile.background }}>
                <ImageWrapper>
                  <img src={tile.img} alt={t("facilities.alt")} />
                </ImageWrapper>
                <TileTextWrapper>
                  <Subheading text={tile.main} />
                  <Paragraph text={tile.second} />
                  <OptionalInfo>{t("facilities.optional_info")}</OptionalInfo> 
                </TileTextWrapper>
              </TileWrapper>
            </Zoom>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Facilities;
