import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { COLORS } from "../utils/colors";
import Topbar from "../components/topbar/index";
import Header from "../components/PageHeader";
import ReservationBanner from "../components/ReservationBaner";
import AboutUs from "../components/AboutUs";
import Facilities from "../components/Facilities";
import Equipment from "../components/Equipment";
import Contact from "../components/Contact";
import Gallery from "../components/Gallery";
import CookieConsent from "react-cookie-consent";
import Footer from "../components/Footer";

const HomeWrapper = styled.div``;

const Home = ({ lang, handleLanguageChange }) => {
  const { t } = useTranslation();
  return (
    <HomeWrapper>
      <Topbar lang={lang} handleLanguageChange={handleLanguageChange} />
      <Header />
      <ReservationBanner />
      <AboutUs />
      <Facilities />
      <Equipment />
      <Gallery />
      <Contact />
      <Footer />
      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName"
        buttonText={t("cookies.confirm")}
        style={{
          background: COLORS.primary,
          fontFamily: "Roboto Slab",
          fontSize: "12px",
        }}
        buttonStyle={{
          background: COLORS.white,
          color: COLORS.primary,
          fontSize: "13px",
        }}
        overlay
      >
        {t("cookies.alert")}
      </CookieConsent>
    </HomeWrapper>
  );
};

export default Home;
