import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors.js";
import { queries } from "../../utils/queries.js";
import { languages } from "../../utils/languages.js";

const LanguagePickerWrapper = styled.div`
  select {
    border: none;
    background: transparent;
    color: ${COLORS.white};
    font-weight: 500;
    outline: none;

    &:hover {
      font-weight: 700;
    }
  }

  option {
    color: ${COLORS.primary};
    font-weight: 500;
    font-family: "Roboto";
  }

  @media ${queries.laptop} {
    select {
      color: ${COLORS.primary};
    }
  }
`;

const LanguagePicker = ({ lang, handleLanguageChange }) => {
  return (
    <LanguagePickerWrapper>
      <select value={lang} onChange={handleLanguageChange}>
        {languages.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.value.toUpperCase()}
            </option>
          );
        })}
      </select>
    </LanguagePickerWrapper>
  );
};

export default LanguagePicker;
