import React from "react";
import { styled } from "styled-components";

const HeadingProps = styled.h1`
  margin-bottom: 15px;
  font-weight: bold;
  color: #333;
  letter-spacing: 1px;
  text-transform: ${(props) => (props?.uppercase ? "uppercase" : "unset")};
  font-style: ${(props) => (props?.italic ? "italic" : "unset")};
`;

const SubheadingProps = styled.h3`
    margin-bottom: 12px;
    font-weight: lighter;
    color: #333;
    letter-spacing: 0.9px;
    text-transform: ${(props) => (props.uppercase ? "uppercase" : "unset")};
    font-style: ${(props) => (props.italic ? "italic" : "unset")};
}
`;

const ParagraphProps = styled.p`
    margin-bottom: 8px;
    font-weight: 300;
    color: #333;
    letter-spacing: 0.6px;
    text-transform: ${(props) => (props.uppercase ? "uppercase" : "unset")};
    text-align: ${(props) => (props.justified ? "justify" : "unset")};
    font-style: ${(props) => (props.italic ? "italic" : "unset")};
}
`;

const SpanProps = styled.span`
    font-weight: 400;
    color: #333;
    letter-spacing: 0.6px;
    text-transform: ${(props) => (props.uppercase ? "uppercase" : "unset")};
    font-style: ${(props) => (props.italic ? "italic" : "unset")};
}
`;

export function Heading({ text, uppercase, italic, ...props }) {
  return (
    <HeadingProps uppercase={uppercase} italic={italic} style={{ ...props }}>
      {text}
    </HeadingProps>
  );
}

export function Subheading({ text, uppercase, italic, ...props }) {
  return (
    <SubheadingProps uppercase={uppercase} italic={italic} style={{ ...props }}>
      {text}
    </SubheadingProps>
  );
}

export function Paragraph({ text, uppercase, italic, justified, ...props }) {
  return (
    <ParagraphProps
      uppercase={uppercase}
      italic={italic}
      justified={justified}
      style={{ ...props }}
    >
      {text}
    </ParagraphProps>
  );
}
export function Span({ text, uppercase, italic, ...props }) {
  return (
    <SpanProps uppercase={uppercase} italic={italic} style={{ ...props }}>
      {text}
    </SpanProps>
  );
}
