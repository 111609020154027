import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors.js";

const LinkProps = styled.a`
  position: relative;
  color: ${COLORS.primary};
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 5px;
  outline: none;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-radius: 5px;
    background-color: ${COLORS.primary};
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    margin-top: 10px;
  }

  &:hover {
    color: ${COLORS.primary};
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
`;

const Link = ({ href, children }) => {
  return (
    <LinkProps href={href} target="_self">
      {children}
    </LinkProps>
  );
};

export default Link;
