import React, { useEffect, useState, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { styled } from "styled-components";
import { queries } from "../../utils/queries.js";
import { COLORS } from "../../utils/colors.js";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.png";
import Link from "../atoms/Link.js";
import Button from "../atoms/Buttons.js";
import LanguagePicker from "./LanguagePicker.js";

const TopbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.white900};
  box-shadow: 3px 0 10px 3px ${COLORS.black200};
  z-index: 10;
`;

const Logo = styled.div`
  margin: 0 20px;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    height: 80px;
    box-shadow: 3px 0 10px 3px ${COLORS.primary300};
    z-index: 9;
  }

  @media ${queries.tablet} {
    img {
      height: 120px;
    }
  }
`;

const List = styled.ul`
  transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
  display: flex;
  position: fixed;
  right: -100%;
  top: 80px;
  flex-direction: column;
  justify-content: center;
  background-color: ${COLORS.primary900};
  width: 100%;
  height: 100%;
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 8;

  &.active {
    right: 0;
  }

  @media ${queries.tablet} {
    width: 400px;
  }

  @media ${queries.laptop} {
    position: initial;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
  }
`;

const ListElement = styled.li`
  text-transform: uppercase;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;

  a {
    color: ${COLORS.white};

    &:hover {
      color: ${COLORS.white};
      font-weight: 700;
    }
  }

  @media ${queries.laptop} {
    a {
      color: ${COLORS.primary};

      &:hover {
        color: ${COLORS.primary};
        font-weight: 500;
      }
    }
  }
`;

const Hamburger = styled.div`
  display: flex;
  cursor: pointer;
  padding: 1rem;

  @media ${queries.laptop} {
    display: none;
  }
`;

const Topbar = ({ lang, handleLanguageChange }) => {
  const { t } = useTranslation();
  const topbarRef = useRef(null);
  const phone = "tel:+48606783533";
  const menuOptions = [
    { text: t("topbar.home"), section: "header" },
    { text: t("topbar.about_us"), section: "about_us" },
    { text: t("topbar.gallery"), section: "gallery" },
    { text: t("topbar.contact"), section: "contact" },
  ];

  const [click, setClick] = useState(false);

  const handleClick = () => setClick((prev) => !prev);

  const handleClickOutside = (event) => {
    if (topbarRef.current && !topbarRef.current.contains(event.target)) {
      setClick(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClickScroll = (goTo) => {
    var elementPosition = document.getElementById(goTo);
    var elementPositionOffset = document.getElementById(goTo).offsetTop;

    if (elementPosition === "topbar") {
      scrollToTop();
    } else if (elementPosition) {
      window.scrollTo({ top: elementPositionOffset - 80, behavior: "smooth" });
    }

    handleClick();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <TopbarWrapper ref={topbarRef}>
      <Logo onClick={scrollToTop}>
        <img src={logo} alt="bridge-apartments-logo" />
      </Logo>
      <List className={click && "active"}>
        {menuOptions.map((option) => (
          <ListElement
            href="#"
            key={option.text}
            onClick={() => handleClickScroll(option.section)}
          >
            <Link>{option.text}</Link>
          </ListElement>
        ))}
        <LanguagePicker
          lang={lang}
          handleLanguageChange={handleLanguageChange}
        />
        <ListElement
          key="reservation"
          onClick={() => handleClickScroll("contact")}
        >
          <Button variant="primary" text={t("topbar.reservation")} />
        </ListElement>
        <ListElement key="contact">
          <a target="_self" href={phone} type="tel">
            <Button
              variant="secondary"
              text={t("topbar.call_us")}
              icon="true"
            />
          </a>
        </ListElement>
      </List>
      <Hamburger onClick={handleClick}>
        {click ? (
          <FaTimes size={30} style={{ color: COLORS.primary }} />
        ) : (
          <FaBars size={30} style={{ color: COLORS.primary }} />
        )}
      </Hamburger>
    </TopbarWrapper>
  );
};

export default Topbar;
