export const COLORS = {
    white: "#FFF",
    white900: "rgba(255, 255, 255, 0.9)",
    white800: "rgba(255, 255, 255, 0.8)",
    white700: "rgba(255, 255, 255, 0.7)",
    white600: "rgba(255, 255, 255, 0.6)",
    white500: "rgba(255, 255, 255, 0.5)",
    white400: "rgba(255, 255, 255, 0.4)",
    white300: "rgba(255, 255, 255, 0.3)",
    white200: "rgba(255, 255, 255, 0.2)",
    white100: "rgba(255, 255, 255, 0.1)",
    black: "#000",
    black900: "rgba(0, 0, 0, 0.9)",
    black800: "rgba(0, 0, 0, 0.8)",
    black700: "rgba(0, 0, 0, 0.7)",
    black600: "rgba(0, 0, 0, 0.6)",
    black500: "rgba(0, 0, 0, 0.5)",
    black400: "rgba(0, 0, 0, 0.4)",
    black300: "rgba(0, 0, 0, 0.3)",
    black200: "rgba(0, 0, 0, 0.2)",
    black100: "rgba(0, 0, 0, 0.1)",
    base: "#333",
    primary: "#736635",
    primary900: "rgba(115, 102, 53, 0.9)",
    primary800: "rgba(115, 102, 53, 0.8)",
    primary700: "rgba(115, 102, 53, 0.7)",
    primary600: "rgba(115, 102, 53, 0.6)",
    primary500: "rgba(115, 102, 53, 0.5)",
    primary400: "rgba(115, 102, 53, 0.4)",
    primary300: "rgba(115, 102, 53, 0.3)",
    primary200: "rgba(115, 102, 53, 0.2)",
    primary100: "rgba(115, 102, 53, 0.1)"
}