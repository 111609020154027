import React from "react";
import { FaPhone } from "react-icons/fa";
import { css, styled } from "styled-components";
import { COLORS } from "../../utils/colors";

const variantStyles = (variant = "primary") =>
  ({
    primary: css`
      color: ${COLORS.white};
      background-color: ${COLORS.primary};
      box-shadow: none;
      border: none;
      padding: 12px 8px;
      -webkit-transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
      transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);

      &:hover {
        background: transparent;
        color: ${COLORS.primary};
        transition: 0.1s ease-in-out;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.7s cubic-bezier(0.5, 0.24, 0, 1);
        transition: all 0.7s cubic-bezier(0.5, 0.24, 0, 1);
        box-shadow: inset 0px 0px 0px ${COLORS.primary400};
        z-index: -1;
      }

      &:hover::before {
        box-shadow: inset 300px 0px 0px ${COLORS.primary400};
      }

      span {
        color: ${COLORS.white};
      }
    `,
    secondary: css`
      color: ${COLORS.primary};
      box-shadow: 0 0 10px ${COLORS.primary200};
      transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
      border: 2px solid transparent;
      padding: 11px 7px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 0%;
        height: 1px;
        background: ${COLORS.primary};
        box-shadow: inset 0px 0px 0px ${COLORS.primary};
        -webkit-transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
        transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
        z-index: -1;
      }

      &:hover::before {
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 0px;
        width: 0%;
        height: 1px;
        background: ${COLORS.primary};
        -webkit-transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
        transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
        z-index: -1;
      }

      &:hover::after {
        width: 100%;
      }

      &:hover {
        border: 2px solid ${COLORS.primary};
      }
    `,
  }[variant]);

const ButtonWrapper = styled.button`
  position: relative;
  display: inline-block;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background: ${COLORS.white};
  outline: none;
  z-index: 1;

  ${({ variant }) => variantStyles(variant)}

  &:hover {
    box-shadow: 0 0 8px 3px ${COLORS.primary200};
  }

  svg{
    padding-top: 6px;
    transform: rotate(90deg);
  }
`;
const ButtonText = styled.span`
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
`;

function Button({ variant, text, icon, handleClick, type }) {
  return (
    <ButtonWrapper variant={variant} onClick={handleClick} type={type}>
      {icon && (
        <FaPhone size={20} style={{ color: COLORS.primary }} />
      )}
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  );
}

export default Button;
