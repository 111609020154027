import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { COLORS } from "../utils/colors.js";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { Heading, Paragraph, Subheading } from "./atoms/Typography.js";
import SectionContainer from "./atoms/SectionContainer.js";

const HeadingWrapper = styled.div`
  margin-bottom: 20px;
  text-align: center;

  h1 {
    font-family: "MarckScript";
  }
`;

const ListWrapper = styled.ul`
  text-align: justify;
  padding: 0;

  li {
    font-weight: 200;
  }
`;

const Equipment = () => {
  const { t } = useTranslation();

  const list = [
    {
      name: t("equipment.amenities_name"),
      data: [
        t("equipment.amenities.indoor_swimmingpool"),
        t("equipment.amenities.wifi"),
        t("equipment.amenities.parking"),
      ],
      delay: 500,
    },
    {
      name: t("equipment.attractions_name"),
      data: [
        t("equipment.attractions.bikes"),
        t("equipment.attractions.walks"),
        t("equipment.attractions.beach"),
        t("equipment.attractions.playground"),
        t("equipment.attractions.spa"),
      ],
      delay: 1000,
    },
    {
      name: t("equipment.nearby_name"),
      data: [
        t("equipment.nearby.restaurnats"),
        t("equipment.nearby.beach"),
        t("equipment.nearby.parks"),
      ],
      delay: 1500,
    },
    {
      name: t("equipment.equipment_name"),
      data: [
        t("equipment.equipment.bed"),
        t("equipment.equipment.drinks"),
        t("equipment.equipment.tv"),
        t("equipment.equipment.kitchen"),
        t("equipment.equipment.iron"),
        t("equipment.equipment.balcony"),
        t("equipment.equipment.puzzle"),
        t("equipment.equipment.cds"),
      ],
      delay: 2000,
    },
  ];

  return (
    <SectionContainer background={COLORS.black100}>
      <Fade triggerOnce>
        <Container fluid>
          <Row>
            <HeadingWrapper>
              <Heading text={t("equipment.header")} />
              <Subheading text={t("equipment.subheader")} />
            </HeadingWrapper>
            {list.map((tile) => (
              <Col xs={12} sm={6} lg={3} key={tile.name}>
                <Fade down delay={tile.delay} triggerOnce>
                  <Paragraph text={tile.name} />
                  <ListWrapper>
                    {tile.data.map((data) => (
                      <li key={data}>
                        <FaCheck
                          size={8}
                          style={{
                            marginRight: "5px",
                            color: `${COLORS.primary}`,
                          }}
                        />
                        {data}
                      </li>
                    ))}
                  </ListWrapper>
                </Fade>
              </Col>
            ))}
          </Row>
        </Container>
      </Fade>
    </SectionContainer>
  );
};

export default Equipment;
