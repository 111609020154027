import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../utils/colors.js";
import { Zoom } from "react-awesome-reveal";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../images/Dziwnow_8-24_02.jpg";
import img2 from "../images/Dziwnow_8-24_03.jpg";
import img3 from "../images/Dziwnow_8-24_06.jpg";
import img4 from "../images/Dziwnow_8-24_12.jpg";
import img5 from "../images/Dziwnow_8-24_16.jpg";
import img6 from "../images/Dziwnow_8-24_17.jpg";
import img7 from "../images/Dziwnow_8-24_23.jpg";
import img8 from "../images/Dziwnow_8-24_24.jpg";
import img9 from "../images/Dziwnow_8-24_25.jpg";
import img10 from "../images/Dziwnow_8-24_28.jpg";
import img11 from "../images/Dziwnow_8-24_29.jpg";
import img12 from "../images/Dziwnow_8-24_35.jpg";
import img13 from "../images/Dziwnow_8-24_36.jpg";
import img14 from "../images/Dziwnow_8-24_38.jpg";
import img15 from "../images/Dziwnow_8-24_39.jpg";
import img16 from "../images/Dziwnow_8-24_40.jpg";
import img17 from "../images/Dziwnow_8-24_41.jpg";
import img18 from "../images/Dziwnow_8-24_42.jpg";
import img19 from "../images/Dziwnow_8-24_44.jpg";
import img20 from "../images/Dziwnow_8-24_46.jpg";
import img21 from "../images/Dziwnow_8-24_51.jpg";
import img22 from "../images/Dziwnow_8-24_52.jpg";
import img24 from "../images/Dziwnow_8-24_56.jpg";
import img25 from "../images/Dziwnow_8-24_58.jpg";
import img26 from "../images/Dziwnow_8-24_59.jpg";
import img27 from "../images/Dziwnow_8-24_60.jpg";

const GalleryWrapper = styled.div`
  background-color: ${COLORS.white};
  height: auto;
  width: 100%;
`;

const images = [
  {
    src: img1,
  },
  {
    src: img2,
  },
  {
    src: img3,
  },
  {
    src: img4,
  },
  {
    src: img5,
  },
  {
    src: img6,
  },
  {
    src: img7,
  },
  {
    src: img8,
  },
  {
    src: img9,
  },
  {
    src: img10,
  },
  {
    src: img11,
  },
  {
    src: img12,
  },
  {
    src: img13,
  },
  {
    src: img14,
  },
  {
    src: img15,
  },
  {
    src: img16,
  },
  {
    src: img17,
  },
  {
    src: img18,
  },
  {
    src: img19,
  },
  {
    src: img20,
  },
  {
    src: img21,
  },
  {
    src: img22,
  },
  {
    src: img24,
  },
  {
    src: img25,
  },
  {
    src: img26,
  },
  {
    src: img27,
  },
];

const Gallery = () => {
  return (
    <GalleryWrapper id="gallery">
      <Zoom triggerOnce delay={1000}>
        <Carousel style={{ height: "100%", maxHeigh: "600px" }}>
          {images.map((el) => (
            <Carousel.Item
              interval={1000}
              style={{ height: "100%" }}
              key={el.src}
            >
              <img
                className="d-block w-100"
                src={el.src}
                alt="Gallery slide"
                style={{ height: "100%", maxHeight:'600px', objectFit: 'cover' }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Zoom>
    </GalleryWrapper>
  );
};

export default Gallery;
