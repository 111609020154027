import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import * as Yup from "yup";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { queries } from "../../utils/queries.js";
import { Paragraph } from "./Typography";
import Button from "./Buttons.js";

const FormWrapper = styled.div`
  margin-bottom: 20px;
  form {
    button {
      margin-top: 20px;
      width: auto;
    }
    p {
      color: red;
      font-size: 10px;
      font-weight: bold;
    }
  }

  @media ${queries.laptop} {
    margin-bottom: 0;
  }
`;

const ContactForm = () => {
  const form = useRef();
  const { t } = useTranslation();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      from_name: "",
      reply_to: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      from_name: Yup.string().required(t("contact.nameValidation")),
      reply_to: Yup.string()
        .email(t("contact.emailValidationCorrect"))
        .required(t("contact.emailValidation")),
      message: Yup.string().required(t("contact.messageValidation")),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      try {
        emailjs
          .send(
            "service_xrrjhlq",
            "template_fikh241",
            values,
            "bq7-aRt8vZaUqfOwq"
          )
          .then(() => {
            setSubmitting(false);
            resetForm();
            alert(t("message_success"));
          });
      } catch {
        setSubmitting(false);
        alert(t("error"));
      }
    },
  });

  return (
    <FormWrapper id="contact-form">
      <form ref={form} onSubmit={handleSubmit}>
        <label htmlFor="from_name">{t("contact.name")}</label>
        <input
          type="text"
          id="from_name"
          name="from_name"
          placeholder={t("contact.name")}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.from_name}
        />
        {touched.from_name && errors.from_name && (
          <Paragraph text={errors.from_name} />
        )}
        <label htmlFor="reply_to">{t("contact.email")}</label>
        <input
          type="email"
          id="reply_to"
          name="reply_to"
          placeholder="example@email.com"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.reply_to}
        />
        {touched.reply_to && !!errors.reply_to && (
          <Paragraph text={errors.reply_to} />
        )}
        <label htmlFor="message">{t("contact.message")}</label>
        <textarea
          name="message"
          id="message"
          placeholder={t("contact.message") + "..."}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.message}
        />
        {touched.message && !!errors.message && (
          <Paragraph text={errors.message} />
        )}
        <Button
          disabled={isSubmitting}
          variant="primary"
          text={t("contact.send")}
          type="submit"
        />
      </form>
    </FormWrapper>
  );
};

export default ContactForm;
