import React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../utils/colors.js";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { FaFacebook } from "react-icons/fa";
import { Subheading, Paragraph } from "./atoms/Typography.js";
import SectionContainer from "./atoms/SectionContainer.js";
import ContactForm from "./atoms/ContactForm.js";
import Map from "../components/atoms/Map.js";

const Contact = () => {
  const { t } = useTranslation();
  const fb = "https://www.facebook.com/profile.php?id=100092843945442";
  const phone = "+48 606 783 533";
  const mail = "home@bridge-apartments.pl";

  return (
    <SectionContainer background={COLORS.primary100}>
      <div id="contact">
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <Fade delay={500} triggerOnce>
                <div>
                  <Subheading
                    text={t("contact.contact_us")}
                    fontWeight="bold"
                  />
                  <Paragraph text={t("contact.street")} />
                  <Paragraph text={t("contact.city")} />
                  <a target="_self" href={`tel:${phone}`} type="tel">
                    <Paragraph text={phone} color={COLORS.primary} fontWeight="400"/>
                  </a>
                  <a href={`mailto:${mail}`} type="mail">
                    <Paragraph text={t("contact.mail")} color={COLORS.primary} fontWeight="400"/>
                  </a>
                  <a href={fb} target="_blank" rel="noreferrer">
                    <FaFacebook
                      color={COLORS.primary}
                      style={{ marginBottom: "10px" }}
                    />
                  </a>
                </div>
              </Fade>
              <Fade delay={600} triggerOnce>
                <Paragraph text={t("contact.or")} fontFamily="MarckScript" />
              </Fade>
              <Fade delay={700} triggerOnce>
                <ContactForm />
              </Fade>
            </Col>
            <Col xs={12} lg={6}>
              <Fade delay={800} triggerOnce>
                <Map />
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </SectionContainer>
  );
};

export default Contact;
