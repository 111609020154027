import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { COLORS } from "../utils/colors.js";
import { Container, Row } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import { Heading } from "./atoms/Typography.js";

const ReservationBannerWrapper = styled.div`
  position: relative;
  display: block;
  margin: -45px auto 0;
  z-index: 4;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.primary};
  padding: 20px;

  h1 {
    font-family: MarckScript;
    font-style: italic;
    font-weight: 100;
    color: ${COLORS.white};
    text-align: center;
    text-transform: lowercase;
    margin-bottom: 0;
  }
`;

const ReservationBanner = () => {
  const { t } = useTranslation();

  return (
    <ReservationBannerWrapper>
      <Slide duration={1000} delay={500} triggerOnce>
        <Container>
          <Row>
            <ContentWrapper>
              <Heading text={t("reservation_baner.journey")} />
            </ContentWrapper>
          </Row>
        </Container>
      </Slide>
    </ReservationBannerWrapper>
  );
};

export default ReservationBanner;
