import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../utils/colors.js";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import { Heading, Subheading } from "./atoms/Typography.js";
import img1 from "../images/Dziwnow_8-24_27.jpg";
import img2 from "../images/Dziwnow_8-24_31.jpg";
import img3 from "../images/Dziwnow_8-24_37.jpg";
import img4 from "../images/Dziwnow_8-24_42.jpg";
import img5 from "../images/Dziwnow_8-24_58.jpg";
import img6 from "../images/Dziwnow_8-24_62.jpg";

const HeaderWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  button {
    position: absolute;
    top: 50%;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .carousel-inner {
    height: 100%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${COLORS.primary400};
      z-index: 1;
    }
  }

  .carousel-caption {
    margin: auto;
    top: 0;
    height: fit-content;
    z-index: 2;
  }
`;

const images = [
  {
    src: img1,
  },
  {
    src: img2,
  },
  {
    src: img3,
  },
  {
    src: img4,
  },
  {
    src: img5,
  },
  {
    src: img6,
  },
];

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper id="header">
      <Carousel style={{ height: "100%" }} indicators={false}>
        {images.map((el) => (
          <Carousel.Item
            interval={1000}
            style={{ height: "100%" }}
            key={el.src}
          >
            <img
              className="d-block w-100"
              src={el.src}
              alt="Gallery slide"
              style={{ height: "100%" }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <Carousel.Caption>
        <Fade delay={500} triggerOnce>
          <Heading
            uppercase="true"
            text={t("header.apartments_for_rent")}
            color={COLORS.white}
          />
          <Subheading uppercase="true" text="Dziwnów" color={COLORS.white} />
        </Fade>
      </Carousel.Caption>
    </HeaderWrapper>
  );
};

export default Header;
